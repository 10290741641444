<template>

<div class="main content mb-5">
  <NotResults v-if="error==204" code="204"/>
        <div v-else class="container">
            <div class="row">
                <div id="property-listing" class="grid-style1 clearfix col-md-12" >
                  <Preloader v-if="!developments" />
                    <div v-else class="row justify-content-center">
                        <DevelopmentCard v-for="(item, i) in developments"
                        :key="item.folio"
                        :name="item.folio"
                        :index="i"
                        :item="item" />
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/layout/Preloader.vue";
import Banner from "@/components/layout/Banner.vue";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
import SearchForm from "@/components/search/SearchForm.vue";

export default {
  components: {
    Banner,
    DevelopmentCard,
    Preloader,
    SearchForm,
  },
  computed: {
    ...mapGetters({
      developments: "_getDevelopments",
    }),
  },
  created() {
    this.$store.dispatch("getDevelopments");
  },
};
</script>
