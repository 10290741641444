<template>
    <div  :class="[(nameRoute!='Agent')?'col-lg-4':'col-lg-12',  'agency-detail clearfix']">
        <div class="info text-center">
          <div class="img-agent">
            <div class="gotoAgent">
              <button
              v-if="nameRoute!='Agent'"
              @click="goToAgent()" 
              class="btn"
              type="submit">
                {{ $t("components.agent_card.link") }}
              </button>
            </div>
            <img
              v-if="agent"
              :src="agent.image ? agent.image : '/images/avatar.png'"
              :alt="`${agent.fullname}-img`"
              class="img-responsive"
            />
          </div>
            <header>
                <h2> <small>{{agent.fullname}} </small></h2>
            </header>
            <ul class="contact-us">
                <li>
                    <i class="fa fa-envelope"></i>
                    <a v-if="agent.email" :href="'mailto:' + agent.email" target="_blank">{{agent.email}}</a>
                </li>
                <li>
                    <i class="fa fa-phone"></i> 
                    <a v-if="agent.cellphone" :href="'tel:' + agent.cellphone" target="_blank">{{agent.cellphone}}</a> 
                </li>
            </ul>
            <ul class="social-networks">
                <!--
                <li v-if="agent.fb"><a :href="agent.fb"><i class="fa fa-facebook"></i></a></li>
                <li v-if="agent.tweet"><a :href="agent.tweet"><i class="fa fa-twitter"></i></a></li>
                <li v-if="agent.instagram"><a :href="agent.instagram"><i class="fa fa-instagram"></i></a></li>
                <li v-if="agent.gmas"><a :href="agent.gmas"><i class="fa fa-google-plus"></i></a></li>
                <li v-if="agent.linkedin"><a :href="agent.linkedin"><i class="fa fa-linkedin"></i></a></li>
                -->
                <li ><a :href="agent.fb"><i class="fab fa-facebook"></i></a></li>
                <li ><a :href="agent.tweet"><i class="fab fa-twitter"></i></a></li>
                <li ><a :href="agent.instagram"><i class="fab fa-instagram"></i></a></li>
                <li ><a :href="agent.youtube"><i class="fab fa-youtube"></i></a></li>
                <li ><a :href="agent.gmas"><i class="fab fa-google-plus"></i></a></li>
                <li ><a :href="agent.linkedin"><i class="fab fa-linkedin"></i></a></li>

            </ul>
        </div>
    </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);

      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>

<style scoped>
.staff img{
    object-fit: contain;
    object-position: center;
    width: 100%;
}
p,a{
    overflow-wrap: break-word;
}
a{
    cursor: pointer;
}
  .img-responsive{
    width: 100% !important;
    height: 255px !important;
    object-fit: contain;
  }
.img-agent{
  position: relative;
  display: block;
}
.gotoAgent{
  position: absolute;
  top: 0px;
  left: 48px;
  z-index: 8;
}
.gotoAgent button{
  display: block;
  margin-bottom: 5px;
  padding: 4px 8px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 10px;
  border-radius: 4px;
  background: #138ccacf !important;
}
</style>
