<template>
<!-- BEGIN HIGHLIGHT -->
<div class="content about-us">
    <div class="container">
        <div class="row">
            <div class="main col-sm-8">
                <div class="center">
                    <h2 class="section-highlight" data-animation-direction="from-left"
                    data-animation-delay="50">
                        {{$t("pages.about.company.title")}}
                    </h2>
                    <p data-animation-direction="from-left" data-animation-delay="650" v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0">
                        {{$t("pages.about.company.description")}}
                    </p>
                    <p v-else class=" jump-line">
                    <About 
                        :aboutx="activeLocale == 'es' || !info.about_us_eng
                        ? info.about_us	 : info.about_us_eng"
                        />
                    </p>
                    <br />
                    <p class="quote">

                        <span v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" 
                        > {{$t("pages.about.slogan")}}</span
                        >
                        <span v-else class="jump-line">
                        <Slogan 
                            :sloganx="activeLocale == 'es' || !info.our_slogan_eng
                            ? info.our_slogan	 : info.our_slogan_eng"
                            />
                        </span>
                    </p>
                    <br>
                    <p class="darker-text" data-animation-direction="from-left" data-animation-delay="250">
                        {{$t("pages.about.vision.title")}}
                    </p>
                    <p v-if="info.our_vision == 0 & info.our_vision_eng == 0"
                    data-animation-direction="from-left" data-animation-delay="650">
                        {{$t("pages.about.vision.description")}}
                    </p>
                    <p v-else
                    data-animation-direction="from-left" data-animation-delay="650" class=" jump-line">
                    <Vision 
                        :visionx="activeLocale == 'es' || !info.our_vision_eng
                        ? info.our_vision	 : info.our_vision_eng"
                        />
                    </p>
                    <br />
                    <p class="darker-text" data-animation-direction="from-left" data-animation-delay="250">
                        {{$t("pages.about.mision.title")}}
                    </p>
                    <p v-if="info.our_mission == 0 & info.our_mission_eng == 0"
                    data-animation-direction="from-left" data-animation-delay="650">
                    {{$t("pages.about.mision.description")}}
                    </p>
                    <p v-else
                    data-animation-direction="from-left" data-animation-delay="650" class=" jump-line">
                    <Mision 
                        :misionx="activeLocale == 'es' || !info.our_mission_eng
                        ? info.our_mission	 : info.our_mission_eng"
                        />
                    </p>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="mt-5">
                    <img v-if="info" class="logo m-1" :src="info.logoClean" alt="logo_web" />
                    <h3 v-else> </h3>
                </div>
                
                <div class="mt-5">
                    <p class="darker-text text-center" data-animation-direction="from-left" data-animation-delay="250">
                        {{$t("pages.about.values.title")}}
                    </p>
                    <p v-if="info.our_values == 0 & info.our_values_eng == 0"
                    data-animation-direction="from-left" data-animation-delay="650" >
                        <ul >
                        <li>
                            {{$t("pages.about.values.example_one.description")}}

                        </li>
                        <li>{{$t("pages.about.values.example_two.description")}}</li>
                        </ul>
                    </p>
                    <p v-else
                    data-animation-direction="from-left" data-animation-delay="650" class="jump-line">
                    <Values 
                        :valuesx="activeLocale == 'es' || !info.our_values_eng
                        ? info.our_values	 : info.our_values_eng"
                        />
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END HIGHLIGHT -->

</template>


<script>
import { mapGetters } from 'vuex';
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
export default {
    components:{
    About,
    Slogan,
    Mision,
    Vision,
    Values,
    },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
        activeLocale() {
        return this.$i18n.locale;
        },
    },
}
</script>

<style scoped>
.logo{
    width: 40% !important;
}

.mt-5{
    margin-top: 40px;
}
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>