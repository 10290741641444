<template>
    <div class="content">
			<div class="container">
				<div  v-if="code !=208" class="row">
				
					<!-- BEGIN MAIN CONTENT -->
					<div class=" col-sm-6">
						<div class="e404 clearfix">
							<div>
								<strong><span v-for="(number, i) in splitCode" :key="number" :index="i">{{number}}</span></strong>
								ERROR 
							</div>
						</div>
					</div>	
					<!-- END MAIN CONTENT -->
					
					<!-- BEGIN SIDEBAR -->
					<div class="sidebar col-sm-5 ">
						
						<div id="e404-side">
							<h3>{{$t(`components.error_section.${code}.title`)}}</h3>
							
							<p><br/><br/>{{$t(`components.error_section.${code}.description`)}}</p>
						
							<p class="center"><br/><a href="/" class="btn btn-default-color">{{$t("layout.not_found.go_home")}}</a></p>
						</div>
					</div>
					<!-- END SIDEBAR -->
					
				</div>
				<div v-else class="notfound">
					<div class="notfound-404">
						<h3>{{$t(`components.error_section.${code}.title`)}}</h3>
						<img v-if="nameRoute=='Agents'" class="text-center my-3" src="/images/agentg.png" alt="no-agents-yet"> 
						<img v-if="nameRoute=='Agent'" class="text-center my-3"  src="/images/no-propsg.png" alt="no-properties-agent">
					</div>
					<br>
				</div>
			</div>
		</div>
</template>

<script>
export default {
props:{
	code:{
		typeo:String,
		require:true
	}
},
computed:{
	splitCode(){
		return this.code.split("")
	},
	nameRoute() {
		return this.$route.name;
	},
	}
}
</script>
<style scoped>
.notfound img{
  width: 150px;
}
</style>