<template>
  <div class="item col-sm-4" @click="goToDevelopment(item.folio, item.developmentNameEs)">
    <!-- Set width to 4 columns for grid view mode only -->
    <div class="image">
      <a  @click="goToDevelopment(item.folio, item.developmentNameEs)">
        <h3 class="name-dev">{{ item.developmentNameEs }}</h3>
        <span class="location" v-if="item.colony || item.city">{{ item.colony }}, {{ item.city }}</span>
      </a>
      <img :src="item.image.smallphoto" alt="" />
    </div>
    <div class="price" v-if="item.priceFrom != 0">
      <i class="fa fa-home"></i>
      <span>{{ item.priceFrom }}</span>
    </div>
    <ul class="amenities">
      <li> # {{item.folio}}</li>      
      <li><p class="dev">*PRECIOS SUJETOS A CAMBIOS SIN PREVIO AVISO</p></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      info:'_getInfoCompany',
    }),
  },
  methods: {
    goToDevelopment() {
      let nameDevFilt = services.normalize(this.item.developmentNameEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Development",
        params: {
          folio,
          nameDevFilt,
        },
      });
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
@media (max-width: 991px) {
  #contain_tarjet_property {
    width: 70%;
  }
}
@media (max-width: 520px) {
  #contain_tarjet_property {
    width: 100%;
  }
}

.dev{
  font-size: 12px;
}

.name-dev{
    white-space: pre-wrap;
  }
</style>
