<template>
  <main>
    <Preloader v-if="!prop" />
    <div v-else>
      <Banner
        :image="prop.images[0].largefile"
        :pageTitle="
          activeLocale == 'es' || !prop.propertyNameEn
            ? prop.propertyNameEs
            : prop.propertyNameEn
        "
      />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="main-2 col-sm-8">
              <!-- PROPERTY TITLE -->
              <h1 v-if="prop.propertyNameEs" class="property-title mt-title">
                {{ prop.propertyNameEs }}
                <small v-if="prop.address">{{ prop.address }}</small>
              </h1>
              <!-- BEST INFORMATION -->
              <div class="property-topinfo">
                <ul
                  v-if="
                    prop.bedrooms != '0' ||
                      prop.bathrooms != '0' ||
                      prop.m2c != '0' ||
                      prop.toperation != '0'
                  "
                  class="amenities"
                >
                  <li v-if="prop.typepropertyEs">
                    <i class="icon-house"></i> {{ prop.typepropertyEs }}
                  </li>
                  <li v-if="prop.m2c">
                    <i class="icon-area"></i> {{ prop.m2c }} m<sup>2</sup>
                  </li>
                  <li v-if="prop.bedrooms">
                    <i class="icon-bedrooms"></i> {{ prop.bedrooms }}
                  </li>
                  <li v-if="prop.bathrooms">
                    <i class="icon-bathrooms"></i> {{ prop.bathrooms }}
                  </li>
                  <li v-if="prop.nameTower">
                    {{$t("pages.property_detail.nameTower")}} {{ prop.nameTower }}
                  </li>
                  <li v-if="prop.numberTower">
                    {{$t("pages.property_detail.numberTower")}} {{ prop.numberTower}}
                  </li>
                </ul>
                <div v-if="prop.folio != '0'" id="property-id" class="folio">
                  # {{ prop.folio }}
                </div>
              </div>

              <!-- PRICE  -->

              <div>
                <div class="row" v-if=" prop.operation.sold != 0 || prop.operation.opportunity_sold != 0 ">
                  <div v-if="prop.operation.sold != 0" class="col-md-6">
                    <h2>
                      {{ prop.operation.sold }}
                    </h2>
                    <strong
                      :class="[
                        prop.operation.opportunity_sold != 0 ? 'not-price' : '',
                        'text-success h1 mb-3',
                      ]"
                    >
                      {{ prop.prices.sold }}
                    </strong>
                  </div>
                  <div v-if="prop.operation.rent != 0" class="col-md-6">
                    <h2>
                      {{ prop.operation.rent }}
                    </h2>
                    <strong
                      :class="[
                        prop.operation.opportunity_rent != 0 ? 'not-price' : '',
                        'text-success h1 mb-3',
                      ]"
                    >
                      {{ prop.prices.rent }}
                    </strong>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-if="prop.operation.opportunity_sold != 0"
                    class="col-md-6"
                  >
                    <h2>
                      {{ prop.operation.opportunity_sold }}
                    </h2>
                    <strong>
                      {{ prop.prices.opportunity_sold }}
                    </strong>
                  </div>
                  <div
                    v-if="prop.operation.opportunity_rent != 0"
                    class="col-md-6"
                  >
                    <h2>
                      {{ prop.operation.opportunity_rent }}
                    </h2>
                    <strong>
                      {{ prop.prices.opportunity_rent }}
                    </strong>
                  </div>
                </div>
                
              </div>
              <br />
              <!-- CAROUSEL -->
              <DetailCarousel :items="prop.images" />
              <br />

              <!-- DESCRIPTION -->
              <div class="style1 mt-5">
                <h2 class="text-center">
                  {{ $t("pages.property_detail.description_prop") }}
                </h2>
                <div v-if="prop.descriptionEs != 0">
                  <h4>Español</h4>
                  <pre>
                      <p>{{ prop.descriptionEs }}</p>
                  </pre>
                </div>
                <div v-if="prop.descriptionEn != 0">
                  <h4>English</h4>
                  <pre>
                      <p>{{ prop.descriptionEn }}</p>
                  </pre>
                </div>
              </div>

              <!-- AMENITIES -->
              <div
                v-if="
                  prop.interiorAmenities ||
                    prop.otherAmenities ||
                    prop.exteriorAmenities ||
                    prop.developmentAmenities ||
                    prop.developmentServices ||
                    prop.services
                "
              >
                <h2 class="text-center">
                  {{ $t("pages.property_detail.amenities_prop") }}
                </h2>
                <div class=" mt-2 mb-2">
                  <ul class="property-amenities-list">
                    <!-- A. Interiors -->
                    <li v-if="prop.interiorAmenities || prop.otherAmenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_interior") }}
                      </h4>
                    <div class="row">
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.hall || prop.interiorAmenities.sala">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_sala") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.kitchen  || prop.interiorAmenities.cocina">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_cocina") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.dinning_room || prop.interiorAmenities.comedor">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_comedor") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.service_area || prop.interiorAmenities.area_servicio">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_areadeservicio") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.amueblado">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_amueblado") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cuartoTv">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_cuartoTV") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.estudio">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_estudio") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cocinaIntegral">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_cocinaIntegral") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoLavado">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_ctoLavado") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoServBaño">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_ctoServBano") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.area_servicio">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_ctoServBano") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.aa">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_aa") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.minisplit">
                          <i class="fas fa-check"></i>
                          Mini split
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ventiladores">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_ventiladores") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.calentadorAgua">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_calentadorAgua") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.casaInteligente">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_casaInteligente") }}
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.microondas">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_microondas") }} 
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.lavaPlatos">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_lavaPlatos") }} 
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.camaraFria">
                          <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.int_camaraFria") }} 
                      </p>
                      <p class="amenidades col-md-4" v-if="prop.interiorAmenities.central">
                          <i class="fas fa-check"></i>
                          Central
                      </p>
                    </div>
                    </li>
                    <!-- A. Exteriors -->
                    <li v-if="prop.exteriorAmenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_exterior") }}
                      </h4>
                      <div class="row">
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.balcon">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_balcon") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.terraza">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_terraza") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jardin">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_jardin") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.alberca">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_alberca") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.elevador">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_elevador") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.estacionamiento">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_estacionamiento") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bbq">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_bbq") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bar">
                            <i class="fas fa-check"></i>
                            Bar
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.gimnasio">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_gimnasio") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.muelle">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_muelle") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cisterna">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_cisterna") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jacuzzi">
                            <i class="fas fa-check"></i>
                            Jacuzzi
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.plantaElectrica">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_plantaElectrica") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cercoElectrico">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_cercoElectrico") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.puertaMuLock">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_puertaMuLock") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.almacen">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_almacen") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.panelesSolares">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_panelesSolares") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaManiobras">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_areaManiobras") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.casetaSeguridad">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_casetaSeguridad") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasMetalicas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_cortinasMetalicas") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaDescarga">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_areaDescarga") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasAnticiclonicas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_cortinasAnticiclonicas") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.techado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_techado") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.solarium">
                            <i class="fas fa-check"></i>
                            Solarium
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.patio_servicio">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ext_patioservicio") }}
                        </p>
                      </div>
                    </li>

                    <!-- A. Development -->
                    <li v-if="prop.developmentAmenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_development") }}
                      </h4>
                      <div class="row">
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.condominio">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_condominio") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.cafetera">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_cafetera") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubGolf">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_clubGolf") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.casaClub">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_casaClub") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.alberca_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_alberca_d") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.muelle_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_muelle_d") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubPlaya">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_clubPlaya") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.spa">
                            <i class="fas fa-check"></i>
                            SPA
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.gimnasio_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_gimnasio_d") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.canchaTenis">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_canchaTenis") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.juegosInf">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_juegosInf") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.lobby">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_lobby") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.elevador_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_elevador_d") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.salonEventos">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_salonEventos") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.restaurante">
                            <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.man_restaurante") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.comercios">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_comercios") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.bodega">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_bodega") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.seguridad">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_seguridad") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.accesoControlado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_accesoControlado") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servicioAdmin">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_servicioAdmin") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.centroNegocios">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_centroNegocios") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.albercaAlquiler">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_albercaAlquiler") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servConcierge">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_servConcierge") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.otherServices">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_otherServices") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2constructionDev">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_m2constructionDev") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2greenArea">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_m2greenArea") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.palapa">
                            <i class="fas fa-check"></i>
                            Palapa
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.marina">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_marina") }} 
                        </p>
                      </div>
                    </li>

                    <!-- A. Services -->
                    <li v-if="prop.developmentServices || prop.services">
                      <h4>{{ $t("pages.property_detail.services_prop") }}</h4>
                      <div class="row">
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.mantenimiento">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_mantenimiento") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servTransp">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servTransp") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.tour">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_tour") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.tiendaComestibles">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_tiendaComestibles") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servLimpieza">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servLimpieza") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servAgua">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servAgua") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servLocalCel">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servLocalCel") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.rampasSillasRuedas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_rampasSillasRuedas") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.luz">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_luz") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.agua">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_agua") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.gas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_gas") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.circuitoCerrado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_circuitoCerrado") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.energiaSustentable">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_energiaSustentable") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.alumbrado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_alumbrado") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.cableTV">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_cableTV") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.wifi">
                            <i class="fas fa-check"></i>
                            Wi-Fi
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.concierge">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_concierge") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.mascotas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_mascotas") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.fumar">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_fumar") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.sistemaDeAlarma">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_sistemaDeAlarma") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servTelefono">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servTelefono") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.camarasDeSeguridad">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_camarasDeSeguridad") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.drenaje">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_drenaje") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.pavimento">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_pavimento") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.poolRentas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_poolRentas") }} 
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <br />


              <!-- VIDEO -->
              <div v-if="prop.video">
                <div class="col-md-12 no-gutters mt-3 mb-4">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.video") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <iframe
                      width="100%"
                      height="315"
                      :src="prop.video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>

            <div class=" sidebar gray col-sm-4">
              <!-- CONTACT -->
              <h2 class="section-title first">
                {{ $t("pages.property_detail.contact_us") }}
              </h2>
              <div class=" mt-5">
                <div style="text-align: center">
                  <p class="mb-0">
                    <a
                      class="banner-button"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Contactar en WhatsApp"
                      target="_blank"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_agent'
                        )}: ${url}&phone=52${prop.agent.cellphone}`
                      "
                    >
                     {{ $t("pages.property_detail.agent_phone") }} : {{ prop.agent.cellphone }}
                    </a>
                  </p>
                  <p class="mb-4 ">
                    <a class="banner-button"> {{ $t("pages.property_detail.agent_email") }} : {{ prop.agent.email }}</a>
                  </p>
                </div>
              </div>
              <div>
                <ContactForm />
              </div>

              <!-- SHARE PROPERTIES -->
              <h2 class="section-title">
                {{ $t("pages.property_detail.share_title") }}
              </h2>
              <div class="share-wraper">
                <ul class="social-networks">
                  <li>
                    <a
                      :href="
                        `https://www.facebook.com/sharer/sharer.php?u=${url}`
                      "
                      target="_blank"
                      ><i class="fab fa-facebook" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      title="Compartir por Twitter"
                      :href="`https://twitter.com/home?status=${url}`"
                      target="_blank"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Compartir por WhatsApp"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_public'
                        )}: ${url}`
                      "
                      target="_blank"
                      ><i class="fab fa-whatsapp"></i
                    ></a>
                  </li>
                </ul>
              </div>

              <!-- PROPERTY FLAYERS -->
              <div v-if="prop.flyerEs || prop.flyerEn">
                <h2 class="section-title">
                  {{ $t("pages.property_detail.share_title") }} 
                </h2>
                <div class="share-wraper">
                 <h3 class="title-bf"> {{ $t("pages.property_detail.banners") }}  </h3> 
                 <ul class="banner row justify-content-center">
                   <li v-if="prop.banner1" class="banners col-md-6">
                    <a class="banner-button" target="_blank" :href="prop.banner1">
                      <i class="fa fa-file-image-o"></i> 1 Foto
                    </a>
                   </li>
                   <li v-if="prop.banner3" class="banners col-md-6">
                    <a class="banner-button" target="_blank" :href="prop.banner3">
                      <i class="fa fa-file-image-o"></i> 3 Fotos
                    </a>
                  </li>
                  <li v-if="prop.banner4" class="banners col-md-6">
                    <a class="banner-button" target="_blank" :href="prop.banner4">
                      <i class="fa fa-file-image-o"></i> 4 Fotos
                    </a>
                  </li>
                  <li v-if="prop.banner6" class="banners col-md-6">
                    <a class="banner-button" target="_blank" :href="prop.banner6">
                      <i class="fa fa-file-image-o"></i> 6 Fotos
                    </a>
                  </li>
                 </ul>
                 <h3 class="title-bf"> {{ $t("pages.property_detail.flyers") }}  </h3> 
                  <ul class="banner row justify-content-center">
                    <li v-if="prop.flyerEs" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="prop.flyerEs"
                      >
                        <i class="fa fa-file-pdf-o"></i> FLYER ESPAÑOL
                      </a>
                    </li>
                  


                    <li v-if="prop.flyerEn" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="prop.flyerEn"
                      >
                        <i class="fa fa-file-pdf-o"></i> ENGLISH FLYER
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

               

              <!-- LOCATION -->
              <div class="mb-5" v-if="prop.latitude || prop.longitude">
                <div class="col-md-12 no-gutters mb-5">
                  <div class="col-12">
                    <h2 class="section-title">
                      {{ $t("pages.property_detail.map_title") }}
                    </h2>
                  </div>
                  <div class="col-12 ">
                    <GoogleMap
                      :mapLat="prop.latitude"
                      :mapLng="prop.longitude"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
export default {
  components: {
    Banner,
    Preloader,
    GoogleMap,
    SearchForm,
    ContactForm,
    DetailCarousel,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.prop.bestprice[0];
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");
      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
    //Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };
        this.$store.dispatch("setPropertyClick", accessData);
      });
    }
    this.sliderStyle();
  },
  methods: {
    sliderStyle() {
      setTimeout(function() {
        /*----------------------------------------------------*/
        /*  owlCarousel
					/*----------------------------------------------------*/
        if ($(".nonloop-block-13").length > 0) {
          $(".nonloop-block-13").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            autoplay: true,
            margin: 20,
            nav: false,
            dots: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                margin: 20,
                stagePadding: 0,
                items: 1,
              },
              1000: {
                margin: 20,
                stagePadding: 0,
                items: 2,
              },
              1200: {
                margin: 20,
                stagePadding: 0,
                items: 3,
              },
            },
          });
        }
        if ($(".slide-one-item").length > 0) {
          $(".slide-one-item").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            margin: 0,
            autoplay: true,
            pauseOnHover: false,
            nav: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
          });
        }
        if ($(".nonloop-block-4").length > 0) {
          $(".nonloop-block-4").owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 10,
            nav: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                items: 1,
              },
            },
          });
        }
      }, 400);
      return;
    },
  },
};
</script>

<style scoped>
.amenidades i{
  color:#0b6a98d4 !important;
}
.for-ul {
  column-count: 3 !important;
  border-bottom:  solid 1px #a7a7a7 ;
  padding-bottom: 15px;
}

.mb-5 {
  margin-bottom: 20px;
}
.mt-5 {
  margin-top: 20px;
}

.section-title,
.section-highlight {
  padding-bottom: 15px;
  position: relative;
  text-align: center;
  clear: both;
  display: inline-block;
  width: 100%;
}

.section-title,
.section-highlight {
  margin-bottom: 20px;
  margin-top: 30px;
}

.share-wraper {
  border: 1px solid #e3e3e3;
  margin-bottom: 0px !important;
  padding: 0px 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0 !important;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  /* try */
}
.banners {
  float: left;
}

.title-bf{
   color: #4d4f52;
   margin-top: 10px;
   margin-bottom: 0;
}

.banner-button {
  display: block;
  width: auto;
  border-radius: 5px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  border: 1px solid #919191;

  color: #4d4f52;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.banner-button:hover {
  border: 1px solid #373737;
  color: #0b6a98d4;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}

strong {
  color: #74777c !important;
  font-size: 36px;
  font-weight: 400;
}

.mt-0 {
  margin-top: -5px;
}

.folio {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #0b6a98d4;
}

.first {
  margin-bottom: 0 !important;
  margin-top: 0px !important;
}

.not-price{
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif !important;
  color: #0b6a98d4 !important;
  font-weight: 300 !important;
  margin: 0 !important;
  /*text-rendering: optimizelegibility;*/
}

.mt-title{
  margin-top: 40px !important;
}

.property-title {
    text-transform: none !important;
    font-size: 24pt !important;
    margin-bottom: 40px !important;
    margin-top: 54px !important; 
}

.sidebar.gray .section-title {
    color: #4d4f52 !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}


</style>
