<template>
  <div class="main content mb-5">
    <NotResults v-if="error == 204" code="204" />
    <div v-else class="container">
      <Preloader v-if="!results" />
      <div v-else>
        <div class="row">
          <div id="property-listing" class="grid-style1 clearfix col-md-12">
            <div class="row justify-content-center">
              <template v-if="!fromDevs">
                <PropertyCard
                  v-for="(item, i) in results"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                />
              </template>
              <template v-else>
                <DevelopmentCard
                  v-for="(item, i) in results"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import Preloader from "@/components/layout/Preloader.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
import NotResults from "@/components/layout/NotFound";
export default {
  components: {
    SearchForm,
    PropertyCard,
    DevelopmentCard,
    Preloader,
    NotResults,
  },
  data() {
    return {
      codeShareProperties: 0,
      revRoute: null,
      developments: false,
    };
  },
  computed: {
    ...mapGetters({
      results: "_getResults",
      error: "_getError",
    }),
    fromDevs() {
      if (this.$route.name == "ResultsDevs") {
        return true;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((self) => {
      self.prevRoute = from;
      if (!self.prevRoute.name) {
        self.$router.push({ name: "Home" });
      }
    });
  },
};
</script>
